














































































































































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class About extends Vue {
  public mounted() {
    const title = document.querySelector('head > title');
    if (title !== null) {
      title.innerHTML = 'Обо мне';
    }
  }
}
